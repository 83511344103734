<template>
  <v-list>
    <!--Bulk Edit-->
    <v-list-item v-if="bulkEdit === false" @click="passBulkEdit">
      <v-icon left small>mdi-pencil</v-icon>
      <v-list-item-content>Bulk Edit</v-list-item-content>
    </v-list-item>
    <div v-else-if="bulkEdit === true">
      <!--Bulk Journal Entries-->
      <v-list-item
        @click="passBulkCreateJournalEnries"
        :disabled="selected_transactions.length === 0"
      >
        <v-icon left small :disabled="selected_transactions.length === 0"
          >mdi-file-document-multiple-outline</v-icon
        >
        <v-list-item-content>Create Journal Entries</v-list-item-content>
      </v-list-item>
      <!--Bulk Delete-->
      <v-list-item
        @click="passBulkDelete"
        :disabled="selected_transactions.length === 0"
      >
        <v-icon left small :disabled="selected_transactions.length === 0"
          >mdi-delete</v-icon
        >
        <v-list-item-content>Delete</v-list-item-content>
      </v-list-item>
      <!--Close Bulk Edit-->
      <v-list-item @click="passCloseEdit">
        <v-icon left small>mdi-close</v-icon>
        <v-list-item-content>Close Edit</v-list-item-content>
      </v-list-item>
    </div>
    <!---->
    <div>
      <v-list-item @click.stop="passUploadStatement">
        <v-icon left small>mdi-file-upload</v-icon>
        <v-list-item-content>Import Bank Statement</v-list-item-content>
      </v-list-item>
    </div>
  </v-list>
</template>

<script>
export default {
  name: "BankSatementsMoreOptionsButton",
  data() {
    return {};
  },
  props: ["bulkEdit", "selected_transactions", "dialog"],
  methods: {
    passBulkEdit() {
      this.$emit("changeBulkEdit", true);
    },
    passCloseEdit() {
      this.$emit("closeBulkEdit", false, []);
    },
    passBulkDelete() {
      this.$emit("triggerBulkDelete");
    },
    passBulkCreateJournalEnries() {
      this.$emit("triggerBulkCreateJournalEntries");
    },
    passUploadStatement() {
      this.$emit("triggerUploadModal", true);
    },
  },
};
</script>
